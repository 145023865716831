<template>
  <div class="leading-normal lg:pl-3" v-if="render">
    <div class="flex flex-col items-start space-y-3 mb-3 w-full">
      <Autocomplete :selectedItem="userDataEdit.location.name" :placeholder="trans('PLACEHOLDER:StartTypingCityName')" type="location" @result-selected="acceptLocation" />
      <CheckboxField
          v-model="userDataEdit.location.isLocationVisible"
          :label="trans('LABEL:ShowLocation')"
      />
    </div>
  </div>
</template>

<script>
import SelectField from "@/utils/crud/components/field-types/Select";
import {mapActions, mapGetters, mapMutations} from "vuex";
import CheckboxField from "@/utils/crud/components/field-types/Checkbox";
import TextField from "@/utils/crud/components/field-types/Text.vue";
import Autocomplete from "@/utils/crud/components/field-types/Autocomplete.vue";


export default {
  name: "LocationForm",
  components: {Autocomplete, TextField, CheckboxField, SelectField},
  props: {
    userData: Object,
    sendRequest: Boolean,
  },
  data() {
    return {
      userDataEdit: null,
      render: false,
      searchLocation: null,
    }
  },
  watch: {
    userDataEdit(val) {
      if(val) {
        this.render = true;
      }
    },
    sendRequest(val) {
      if(val) {
        this.setSendRequestStatus(false);
        this.sendEditRequest({
          location_id: this.userDataEdit.location.id,
          is_location_visible: !!this.userDataEdit.location.isLocationVisible
        })
      }
    }
  },
  methods: {
    ...mapMutations('profile', ['setSendRequestStatus']),
    ...mapActions('profile', ['sendEditRequest']),
    ...mapActions('dictionaries', ['fetchLocationData']),
    acceptLocation(e) {
      this.userDataEdit.location.id = e.id;
    }
  },
  computed: {
    ...mapGetters('dictionaries', ['locationsData'])
  },
  mounted() {
    this.userDataEdit = this.userData;
    if(this.userDataEdit.location.id) {
      this.searchLocation = this.userDataEdit.location.name;
    }
  }
}
</script>
