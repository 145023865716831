<template>
  <div class="leading-normal lg:pl-3" v-if="render">
    <div class="flex flex-row items-center justify-between mb-3">
      <span class="w-170 text-base">
        {{ trans('LABEL:Alcohol') }}
      </span>
      <SelectField
          class="w-300"
          v-model="userDataEdit.alcohol.id"
          :placeholder="''"
          text-attr="slugTrans"
          :options="stimulantsAndAnimalsData.alcohols"
      />
    </div>
    <div class="flex flex-row items-center justify-between mb-3">
      <span class="w-170 text-base">
        {{ trans('LABEL:Cigarette') }}
      </span>
      <SelectField
          class="w-300"
          v-model="userDataEdit.cigarette.id"
          :placeholder="''"
          text-attr="slugTrans"
          :options="stimulantsAndAnimalsData.cigarettes"
      />
    </div>
	  <div class="flex flex-row items-center justify-between mb-3">
      <span class="w-170 text-base">
        {{ trans('LABEL:NarcoticSubstance') }}
      </span>
		  <SelectField
				  class="w-300"
				  v-model="userDataEdit.narcoticSubstance.id"
				  :placeholder="''"
				  text-attr="slugTrans"
				  :options="stimulantsAndAnimalsData.narcoticSubstances"
		  />
	  </div>
  </div>
</template>

<script>
import SelectField from "@/utils/crud/components/field-types/Select";
import {mapActions, mapGetters, mapMutations} from "vuex";
export default {
  name: "StimulantForm",
  components: {SelectField},
  props: {
    userData: Object,
    sendRequest: Boolean,
  },
  data() {
    return {
      userDataEdit: null,
      render: false,
    }
  },
  watch: {
    userDataEdit() {
      this.render = true;
    },
    sendRequest(val) {
      if(val) {
        this.setSendRequestStatus(false);
        this.sendEditRequest({
          alcohol_id: this.userDataEdit.alcohol.id,
          cigarette_id: this.userDataEdit.cigarette.id,
          narcotic_substance_id: this.userDataEdit.narcoticSubstance.id,
        })
      }
    }
  },
  methods: {
    ...mapMutations('profile', ['setSendRequestStatus']),
    ...mapActions('profile', ['sendEditRequest']),
    ...mapActions('dictionaries', ['fetchMaritalData', 'fetchParentalData'])
  },
  computed: {
    ...mapGetters('dictionaries', ['stimulantsAndAnimalsData'])
  },
  mounted() {
    this.userDataEdit = structuredClone(this.userData);
  }
}
</script>
