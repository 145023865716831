<template>
  <div class="autocomplete w-full">
    <TextField
        class="w-full"
        :placeholder="placeholder"
        v-model="search"
        @input="searchAction"
        @focus="isFocused = true"
        @blur="isFocused = false"
        ref="autocompleteInput"
    >
	    <template #iconEnd>
		    <CloseButton @clicked="clearInput" :show="!isEmpty(search)" />
	    </template>
    </TextField>
    <ul
        class="autocomplete-results"
        v-if="data && data.length > 0 && !isEmpty(search)"
    >
      <li v-for="item in data"
          class="autocomplete-result"
          @click="manageClick(item)"
      >
        {{ item.textAttr }}
      </li>
    </ul>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import debounce from "lodash/debounce";
import {isEmpty} from "lodash/lang";
import TextField from "@/utils/crud/components/field-types/Text.vue";
import BaseIcon from "@/utils/icons/BaseIcon.vue";
import CloseButton from "@/utils/crud/components/CloseButton.vue";

  export default {
    name: 'Autocomplete',
    components: {CloseButton, BaseIcon, TextField},
    data() {
      return {
        search: '',
        data: [],
        isEmpty: isEmpty,
        isFocused: false,
      };
    },
    props: {
      placeholder: String,
      type: String,
      selectedItem: String,
    },
    watch: {
      locationsData(val) {
        if(val && val.length > 0) {
          this.data = val;
        }
      },
      search(val) {
        if(val && val.length === 0) {
          this.$emit('result-selected', {id: null})
        }
      }
    },
    computed: {
      ...mapGetters('dictionaries', ['locationsData']),
    },
    methods: {
      ...mapActions('dictionaries', ['fetchLocationData']),
      searchAction: debounce(function (e) {
        if(this.type === 'location') {
          if(!isEmpty(this.search) && this.isFocused) {
            this.fetchLocationData({search: this.search});
          }
        }
      }, 500),

      manageClick(item) {
        this.search = item.cityName;
        this.data = [];
        this.$emit('result-selected', {id: item.id});
      },

	    clearInput() {
				this.search = null;
		    this.$emit('result-selected', {id: null});
	    }
    },
    mounted() {
      if(this.selectedItem) {
        this.search = this.selectedItem;
      }
    }
  };
</script>

<style>
.autocomplete {
  position: relative;
}

.autocomplete-results {
  padding: 0px;
  margin: 0px;
  border-left: 2px solid theme('colors.grayedHR');
  border-right: 2px solid theme('colors.grayedHR');
  border-bottom: 2px solid theme('colors.grayedHR');
  height: 120px;
  overflow: auto;
  font-size:1rem;
  text-indent: 0.5rem;
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.autocomplete-result {
  list-style: none;
  text-align: left;
  padding: 4px 2px;
  cursor: pointer;
}

.autocomplete-result.is-active,
.autocomplete-result:hover {
  background-color: theme('colors.redAccent');
  color: white;
}
</style>