<template>
  <div class="leading-normal lg:pl-3" v-if="render">
    <div class="flex flex-row items-center justify-between mb-3 ">
      <span class="w-170 text-base">
        {{ trans('LABEL:Height') }}
      </span>
      <SelectField
          class="w-300"
          :placeholder="''"
          v-model="userDataEdit.height.value"
          :options="heightData"
      />
    </div>
    <div class="flex flex-row items-center justify-between mb-3">
      <span class="w-170 text-base">
        {{ trans('LABEL:Weight') }}
      </span>
      <SelectField
          class="w-300"
          v-model="userDataEdit.weight.value"
          :placeholder="''"
          :options="weightData"
      />
    </div>
    <div class="flex flex-row items-center justify-between">
      <span class="w-170 text-base">
        {{ trans('LABEL:Shape') }}
      </span>
      <SelectField
          class="w-300"
          v-model="userDataEdit.shape.id"
          :placeholder="''"
          :options="shapeData"
      />
    </div>
  </div>
</template>

<script>
import TextField from "@/utils/crud/components/field-types/Text";
import SelectField from "@/utils/crud/components/field-types/Select";
import {mapActions, mapGetters, mapMutations} from "vuex";
import filters from "@/common/helpers/filters";

export default {
  name: "AppearanceForm",
  components: {SelectField, TextField},
  props: {
    userData: Object,
    sendRequest: Boolean,
  },
  data() {
    return {
      userDataEdit: null,
      heightData: [],
      weightData: [],
      render: false,
    }
  },
  watch: {
    userDataEdit() {
      this.render = true;
    },
    sendRequest(val) {
      if(val) {
        this.setSendRequestStatus(false);
        this.sendEditRequest({
          weight: this.userDataEdit.weight.value,
          height: this.userDataEdit.height.value,
          shape_id: this.userDataEdit.shape.id,
        })
      }
    }
  },
  computed: {
    ...mapGetters('dictionaries', ['shapeData'])
  },
  methods: {
    ...mapMutations('profile', ['setSendRequestStatus']),
    ...mapActions('profile', ['sendEditRequest']),
    ...mapActions('dictionaries', ['fetchShapeData'])
  },
  mounted() {
    this.heightData = filters.prepareOptionsArray(140, 210, 1, false, true, this.trans('LABEL:cm'))
    this.weightData = filters.prepareOptionsArray(40, 150, 1, false, true, this.trans('LABEL:kg'))
    this.userDataEdit = structuredClone(this.userData);
  }
}
</script>
