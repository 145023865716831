<template>
  <div class="leading-normal lg:grid lg:grid-cols-2 gap-1 lg:gap-3 lg:pl-3" v-if="render">
    <div class="flex flex-row items-center" v-for="(search, index) in searchWhatsData" :key="index">
      <CheckboxField
          text-base
          :label="trans(search.name)"
          v-model="searchWhats[search.id]"
      />
    </div>
  </div>
</template>

<script>
import CheckboxField from "@/utils/crud/components/field-types/Checkbox";
import {mapActions, mapGetters, mapMutations} from "vuex";
export default {
  name: "SearchingWhatsForm",
  components: {CheckboxField},
  props: {
    userData: Object,
    sendRequest: Boolean,
  },
  data() {
    return {
      searchWhats: [],
      render: false,
    }
  },
  watch: {
    searchWhats: {
      deep: true,
      immediate: true,
      handler(val) {
        if(val) {
          this.render = true;
        }
      }
    },
    sendRequest(val) {
      if(val) {
        this.setSendRequestStatus(false);
        const payload = [];
        this.searchWhats.filter((val, key) => {
          if(!!val === true) {
            payload.push(key)
          }
        })
        this.sendEditRequest({
          search_whats: payload,
        })
      }
    }
  },
  computed: {
    ...mapGetters('dictionaries', ['searchWhatsData'])
  },
  methods: {
    ...mapMutations('profile', ['setSendRequestStatus']),
    ...mapActions('profile', ['sendEditRequest']),
    ...mapActions('dictionaries', ['fetchSearchWhatsData']),

  },
  mounted() {
    this.searchWhatsData.filter(el => {
      this.$set(this.searchWhats, el.id, !!this.userData.searchWhats.data.some(e => e.id === el.id))
    })
  }
}
</script>
